import './bootstrap'
import '../web-components/components.js'

// alpine
import './alpine/alpine'

// josh
import Josh from 'joshjs'
import 'animate.css/animate.css'
const josh = new Josh({
    // DOM CSS class to Animate, default is "josh-js"
    initClass: 'animate',

    // Animation CSS class from Animate.css library
    animClass: 'animate__animated',

    // Element distance of viewport to triggering the animation. default is 0.2 means 20% of element view animation will trigger
    offset: 0.2,

    // Animation will trigger on Mobile or not. Default is true
    animateInMobile: true,

    // Animation will trigger on newly added element or not. Default is false
    onDOMChange: false,
})

// fancybox
import { Fancybox } from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'
Fancybox.bind('.lightbox', {
    // Your custom options
})

// aplayer
import 'aplayer/dist/APlayer.min.css'
import APlayer from 'aplayer'
window.APlayer = APlayer
