
import { Application } from '@hotwired/stimulus'
const application = Application.start()

// manually importing ux-live-component
// idea stolen from here: https://www.stimulus-components.com/docs/stimulus-clipboard/
import '@symfony/ux-live-component/dist/live.min.css'
import Live from '@symfony/ux-live-component'
application.register('live', Live)


